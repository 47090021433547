<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>新增员工</span>
		</div>
		<div class="course-wrapper-body">
			<div class="item-title">基本信息<i class="el-icon-arrow-up"></i></div>

			<el-form label-width="5.20833vw">
				<el-form-item>
					<div class="head el-icon-plus"></div>
				</el-form-item>

				<el-form-item label="所属组织：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="学校：">
					<el-select placeholder="选择学校" value="" class="input-select">
						<el-option label="学校1" value="选项1"></el-option>
						<el-option label="学校2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="所在部门：">
					<el-select placeholder="选择部门" value="" class="input-select">
						<el-option label="部门1" value="选项1"></el-option>
						<el-option label="部门2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="角色：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="手机号码：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="员工姓名：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="证件类型：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="证件号码：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="性别：">
					<el-radio-group>
						<el-radio-button label="男性"></el-radio-button>
						<el-radio-button label="女性"></el-radio-button>
						<el-radio-button label="未知"></el-radio-button>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="个人简介：">
					<el-input type="textarea" maxlength="100" class="textarea-inputted" placeholder="输入内容" show-word-limit></el-input>
				</el-form-item>
			</el-form>

			<div class="item-title">班级信息<i class="el-icon-arrow-up"></i></div>

			<div class="classes">
				<div class="course-wrapper-function">
					<div class="function-left">
						<el-select placeholder="选择年级" value="" class="input-select">
							<el-option label="年级1" value="选项1"></el-option>
							<el-option label="年级2" value="选项2"></el-option>
						</el-select>
						<el-select placeholder="选择班级" value="" class="input-select">
							<el-option label="班级1" value="选项1"></el-option>
							<el-option label="班级2" value="选项2"></el-option>
						</el-select>

						<el-button type="primary" icon="el-icon-plus">添加</el-button>
					</div>
				</div>

				<el-table :data="[{}]">
					<el-table-column label="年纪"></el-table-column>
					<el-table-column label="班级"></el-table-column>
					<el-table-column label="班主任" align="center">
						<el-switch active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<el-button type="text">取消关联</el-button>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'employeeInsert',
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
/deep/.course-wrapper-body {
	.item-title {
		padding-left: 10px;
		border-left: 8px solid $colorBorder;
		.el-icon-arrow-up {
			float: right;
			color: $colorBorder;
			font-weight: bold;
		}
	}
	.el-form {
		padding: 60px 0;
		margin: 0 auto;
		width: 500px;
		.head {
			display: flex;
			margin: 0 auto 30px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 100px;
			color: #ccc;
			font-size: 14px;
			border: 1px solid #ccc;
			background: #f9f9f9;
			border-radius: 50%;
			cursor: pointer;
			&:before {
				font-weight: bold;
			}
			&:after {
				content: '上传图片';
			}
		}

		.input-select {
			width: 100%;
		}
		.textarea-inputted {
			.el-textarea__inner {
				height: 150px;
			}
		}
	}

	.classes {
		padding-top: 60px;
	}
}
</style>
